@tailwind base;
@tailwind components;
@import "_typo";
@import "_menu";
@import "styles";

.container{
    margin: 0 auto;
    max-width:450px;
    @media(min-width:992px){
        max-width:1440px;
    }
}

@tailwind utilities;

// sections
@import "_header";
@import "_reg-to-vote";
@import "_how-it-works";
@import "_research-shows";
@import "_pers-your-ballot";
@import "_presidential-election";
@import "_faq";
@import "_footer";


@media(min-width:992px){

    .btn, a{
        opacity:1;
        transition: opacity .5s;
        -webkit-transition: opacity .5s;
        &:hover{
            opacity:.65;
            transition: opacity .8s;
            -webkit-transition: opacity .8s;
        }
    }
}






