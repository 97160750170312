#pers-your-ballot{
    position: relative;
    background: #F5F4EE;
    padding:0px 0 0;
    @media(min-width:992px){
        padding:140px 0px 182px;
    }
    .img-bg{
        position: absolute;
        bottom:0;


        &:first-of-type{ left:0;  }
        &:last-of-type{ right:0;  }
    }

    .container{
        position: relative;
        max-width:426px;

        text-align: center;
        img{
            margin-bottom:37px;
            display: inline-block;
        }
    }

    h2{
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 118%;
        text-align: center;
        /* Green */
        color: #00585F;
    }
    p{
         font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 142.5%;
        /* or 23px */

        text-align: center;

        color: #000000;
        margin: 15px auto 23px;
    }
    .btn{
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 85%;
        /* identical to box height, or 14px */

        text-align: center;

        color: #FFFFFF;
        padding:21px 26px;
        background: #00585F;
        border-radius: 10px;
        display: inline-block;

    }
}