
#faq{
    background: #E1F0EE;
    padding:54px 20px;

    .container{
        max-width:849px;
        >div{
            opacity:0;
            max-height: 0;
            overflow:hidden;
            transition: max-height 1s, opacity .5s;
            -webkit-transition: max-height 1s, opacity .5s;

            &.active{
                max-height:200vh;
                opacity:1;
                transition: max-height 2s, opacity 1s;
                -webkit-transition: max-height 2s, opacity 1s;
            }
        }
    }

    .text-container{
        p{
            text-align: center;
            font-family: 'Bebas Kai', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 32px;
            line-height: 38px;

            color: #DE784C;
            a{
                font-family: 'Manrope', sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                /* identical to box height */
                cursor: pointer;

                color: #08B198;
                opacity:1;
                transition: opacity .5s;
                -webkit-transition: opacity .5s;

                &:hover{
                    opacity:.65;

                }
            }
        }

        
    }

    .faq-container{
        font-family: 'Inter' sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 172.02%;
        /* or 28px */
        color: #0E0E0F;

        h2{
            font-family: 'Bebas Kai', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 32px;
            line-height: 38px;
            text-align: center;

            color: #DE784C;
            margin-bottom:50px;
        }


        ul{
            li{

            }
        }

        .accordeon{
            .acc-item{
                border-bottom: 2px solid #FFFFFF;

                .acc-title{
                    font-family: 'Inter' sans-serif;
                    position: relative;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 29px;
                    /* identical to box height */
                    color: #202B40;
                    text-align: left;
                    padding:20px 25px 20px 4px;
                    margin:0;
                    cursor:pointer;
                    .icon{
                        position: absolute;
                        top: 50%;
                        right: 13px;
                        transform: translateY(-50%) rotate(0deg);
                        transition: transform .5s;
                        -webkit-transition: transform .5s;
                    }
                }

                .acc-text{
                    
                    padding: 0 50px 0 4px;
                    max-height:0;
                    overflow:hidden;
                    text-align: left;

                    transition: max-height 1s, padding .5s;
                    -webkit-transition: max-height 1s, padding .5s;

                    
                }

                &.active{
                    .acc-title{
                        .icon{
                            transform: translateY(-50%) rotate(90deg);
                        }
                    }
                    .acc-text{
                        padding-bottom:20px;
                        max-height:100vh;
                        transition: max-height 2s, padding 1s;
                        -webkit-transition: max-height 2s, padding 1s;
                    }

                }
            }
        }
    }
}