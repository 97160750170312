#how-it-works{  
    position: relative;
    padding:387px 20px 107px;
    background: #E1F0EE;
    @media(min-width:992px){
        padding:108px 20px 118px;
    }

    .container{
        max-width:226px;
        @media(min-width:992px){
            $distanceToDesignSize:159px;
            $designWidth:1440px;
            //max-width calulation design width - distance first content * 2
            max-width: calc(1440px - calc(159px * 2));
        }
    }

    .bg-img{
        position:absolute;
        top:0;
        right:0;
        display: block;
        max-width:100%;
        height: 310px;
        min-width:447px;
        @media(min-width:992px){
            height: 337.36px;
        }
    }

    h2{
        margin-bottom:68px;
        font-family: 'Bebas Kai', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 118%;
        /* identical to box height, or 57px */
        text-align: center;
        /* Green */
        color: #00585F;
         @media(min-width:992px){
            margin-top:63px;
            margin-left:32px;
            text-align: left;
            font-size: 64px;
            line-height: 118%;
         }
    }

    .btn{
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 85%;
        text-align: center;
        color: #FFFFFF;
        padding:21px 25px;
        background: #00585F;
        border-radius: 10px;
        @media(min-width:992px){
            margin-top:30px;
            margin-left:32px;
         }
    }

    .box-list{
        display:grid;
        grid-gap:20px;
        grid-template-columns: 1fr;
        @media(min-width:992px){
            grid-template-columns: 25% 25% 25% 25%;
        }
    }
    .box{
        display:grid;
        grid-gap: 22px;
        grid-template-columns: 15px 1fr;
        span{
            &.number{
                font-family: 'Bebas Kai', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 118%;
                /* or 28px */
                text-align: center;
                color: #DE784C;
            }
        }

        p{
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 118%;
            /* or 17px */
            color: #00585F;
        }

        h3{
            font-family: 'Bebas Kai', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 118%;
            /* or 28px */
            color: #00585F;
            margin-bottom: 16px;
        }
    }
}