#header{
    padding:28px 0px 0;
    background: #F5F4EE;
    @media(min-width:992px){
        
         padding:82px 20px;
    }
    .container{
        padding:0 20px;
        max-width:381px;
        @media(min-width:992px){
            max-width:1332px;
        }
    }

    h1{
        font-family: 'Bebas Kai', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 72px;
        line-height: 86px;

        color: #202B40;
        @media(max-width:991px){
            font-size: 72px;
            line-height: 75px;
            text-align: center;
        }
    }
    p{
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 32px;

        color: #363D50;
        margin:16px 5px 32px;
        max-width:540px;
        a{
            color: #DE784C;
            text-decoration: underline;
            white-space: nowrap;
            &:hover{opacity:1;}
        }
        @media(max-width:991px){
            font-weight: 800;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
        }
    }
    .list-icons{
       
        @media(max-width:991px){
            background: white;
            padding:0 25px 28px;
        }
        li{
            @media(max-width:991px){
                min-width:218px;
                margin: 22px auto 0;
                &:first-child{
                    margin: 25px auto 0;
                }
            }
            @media(min-width:992px){
                 margin-top: 25px;
            }
        }
        img{ margin:0 5px; padding:0;}
        h3{
            font-family: 'Bebas Kai', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 85%;
            /* or 15px */
            color: #00585F;
            margin: 0 5px -1px;
            padding: 0;
        }
        p{
            font-family: 'Manrope', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 85%;
            /* or 10px */

            text-align: center;

            color: #00585F;
            margin: 0 5px 0px;
        }
    }
    .submit-form{
        margin-top:30px;
        display: block;
        max-width:602px;
        .msg{
            background:#888;
            color:#fff;
            border-radius:10px;
            max-height:0px;
            padding:0;
            text-align: left;
            transition: max-height .1s, padding .1s;
            -webkit-transition: max-height .1s, padding .1s;

            &.error{
                background:#f57272;
            }
            &.success{
                background:#5ad07f;
            }
            &.active{
                padding:1px;
                max-height:500px;
                transition: max-height 1s;
                -webkit-transition: max-height 1s, padding .1s;
            }
            p{
                margin: 16px 25px;
                font-size: 14px;
                line-height: 16px;
                color:#fff;
                text-align: left;
            }
        }
    }
    form{
        font-family: 'Work Sans', sans-serif;
        
        
        display: grid;
        grid-template-columns:1fr;
        background: #FFFFFF;

        @media(min-width:375px){
            box-sizing: border-box;
            border-radius: 20px;
            grid-template-columns:1fr 72px;
            overflow:hidden;
        }
        @media(min-width:992px){
             grid-template-columns:1fr 132px;
        }
       input{
           &[type="email"],&[type="text"]{
               font-family: 'Manrope', sans-serif;
                outline: 0;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;

                color: #00585F;

                background: #FFFFFF;
                box-sizing: border-box;
                border-radius: 10px 0px 0px 10px;

                color: #000000;
                padding: 31px 27px 31px 27px;
                @media(max-width:374px){
                    color: #000000;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 16px;
                    box-sizing: border-box;
                    border-radius: 10px;
                }
                @media(min-width:374px)and(max-width:992px){
                    padding: 25px 1em 25px;
                }

           }
           &[type="submit"],&[type="button"]{
               font-family: 'Manrope', sans-serif;
                outline: 0; 
                background: #5590BA;
                box-sizing: border-box;
                text-transform: uppercase;
                @media(max-width:374px){
                    border-radius: 10px;
                }

                cursor:pointer;
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: 24px;
                /* identical to box height */

                text-align: center;

                color: #FFFFFF;
                padding: 31px 41px 31px 45px;
                @media(min-width:375px) and (max-width:991px){
                    padding: 23px 19px 23px;
                }
                @media(min-width:992px){

                    opacity:1;
                    transition: opacity .3s;
                    &:hover{
                        opacity:.65;
                        transition: opacity .5s;
                    }
                }
           }
       }
    }

    .text-container{
        max-width:549px;
        margin:0 auto;
    }
    .media-container{
        img{
            @media(max-width:991px){
                margin:28px 0 28px;
            }
        }
        
    }


}