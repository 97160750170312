#menu {
	padding:10px 20px 13px;
	.logo{
		img{width:90px; max-width:inherit;}
		display: block;
		margin:0 auto;
		@media(min-width:992px){
			img{width:100px;}
		}
	}
	.links {
		margin: 22px 20px 22px;
		a {
			font-family: 'Manrope', sans-serif;
			font-style: normal;
			font-weight: 800;
			font-size: 14px;
			line-height: 118%;
			margin: 0 37px;

			text-align: center;

			color: #000000;
			&:hover {
				@apply text-gray-900;
			}
		}
	}
	&.scrolling {
		@screen md {
			@apply elevation-12;
			transition: all 0.3s linear;
		}
	}
}
