
#research-shows{
    background: #F5F4EE;
    padding:55px 20px 65px;
    @media(min-width:992px){
        padding:110px 20px 14px;
    }
    .container{
        // mobile 
        max-width: calc(65px + calc(236px + 24px));
        @media(min-width:992px){
            // desktop 
            //max-width calulation design width - distance first content * 2
            $distanceToDesignSize:216px;
            $designWidth:1440px;
            max-width: calc(1440px - calc(216px * 2));
        }
    }

    h2{
        font-family: 'Bebas Kai', sans-serif;
        margin-bottom:35px;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 118%;
        /* identical to box height, or 57px */
        text-align: center;

        color: #00585F;

        @media(min-width:992px){
            font-size: 64px;
            line-height: 118%;
            margin-bottom:55px;
            text-align: left;
        }
    }
    .list{
        display:grid;
        grid-template-columns: 1fr;
        grid-gap: 25px;

        >div{
            display: grid;
            grid-template-columns: 65px 1fr;
            grid-gap: 20px;

            >div{
                &.number{
                    font-family: 'Bebas Kai', sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 48px;
                    line-height: 118%;
                    /* identical to box height, or 57px */
                    text-align: right;
                    color: #DE784C;
                    &[data-unit='years']{
                        position: relative;
                        span{
                            &:first-child{
                                margin-right: 25px;
                            }
                            &.unit{
                                position: absolute;
                                top: 17px;
                                right: -2%;
                                font-size: 18px;
                                line-height: 118%;
                                text-align: center;
                                color: #DE784C;
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
            }

            p{
                min-height: 95px;
                font-family: 'Inter', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 118%;
                /* or 19px */


                color: #000000;
            }
        }
    }

    .info-box{
        background-image: url(../media/images/sheet.svg);
        background-size: contain;
        background-position:center top;
        padding:63px 42px;
        max-width: 438px;

        h3{
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 172.02%;
            /* identical to box height, or 62px */

            text-align: center;
            color: #00585F;
        }
        p{
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 172.02%;
            /* or 28px */
            margin-top:23px;

            color: #000000;
        }
    }
}