

@font-face {
  font-family: 'Bebas Kai';
  src: url(../fonts/BebasKai-Regular.otf);
  font-weight: normal;
}

@font-face {
  font-family: 'Inter';
  src: url(../fonts/Inter-Regular-slnt=0.ttf);
  font-weight: normal;
}

@font-face {
  font-family: 'Inter';
  src: url(../fonts/Inter-Bold-slnt=0.ttf);
  font-weight: bold;
}