#election-2020 {
  position: relative;
  background: #f5f4ee;
  padding: 0px 0 0;
  @media (min-width: 992px) {
    padding: 0px 0px 600px;
  }

  .img-bg {
    position: absolute;
    bottom: 0;
    top: 0;
    padding-top: 50px;
    @media (min-width: 992px) {
      width: 20%;
    }

    &:first-of-type {
      left: 0;
    }
    &:last-of-type {
      right: 0;
    }
  }

  .container {
    position: relative;
    max-width: 224px;

    text-align: center;
    @media (min-width: 992px) {
      max-width: 436px;

      padding: 140px 0px 600px;
    }
    @media (min-width: 1440px) {
      max-width: 436px;

      padding: 140px 0px 600px;
    }

    img {
      margin-bottom: 37px;
      display: inline-block;
      padding: 7px;
    }
    .title-mobile {
      font-family: Bebas Kai;
      font-style: normal;
      font-weight: normal;
      font-size: 44px;
      line-height: 40px;
      text-align: center;
      color: #0e2549;
      padding-top: 20px;
    }
  }

  h2 {
    font-family: Bebas Kai;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 118%;
    text-align: center;
    color: #0e2549;
  }
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 142.5%;
    /* or 23px */

    text-align: center;

    color: #000000;
    margin: 15px auto 23px;
  }
  .btn {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 85%;
    /* identical to box height, or 14px */

    text-align: center;

    color: #ffffff;
    padding: 21px 26px;
    background: #00585f;
    border-radius: 10px;
    display: inline-block;
  }

  .container-dropdown-line {
    // max-width: 320px;
    // width: 100%;
    // margin: 0 auto;
    padding: 20px 42px 30px 42px;
    @media (min-width: 992px) {
      //   max-width: 900px;
      //   margin: 0 220px 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: -625px;
      position: inherit;
    }
  }
  .menu-line-drop {
    display: none;

    // display: flex;
    flex-direction: column;
    justify-content: center;
    width: 237px;

    background-color: #ffffff;
    background: #ffffff;
    border-radius: 20px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 172.02%;

    color: #00585f;

    @media (min-width: 992px) {
      display: flex;
      justify-content: center;
      width: 600px;
      height: 50px;

      border-radius: 100px;
    }
    > ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: 992px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: center;
        justify-content: space-around;
      }
    }
    > li {
      display: flex;
      // padding: 0px 20px;
      line-height: 350%;
      @media (min-width: 992px) {
        display: flex;
        padding: 0px 20px;
        margin: 0px 10px;
      }
    }
    .arrow {
      padding: 15px;
      @media (min-width: 992px) {
        padding: 0;
      }
    }
  }

  .container-dropdown-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: inherit;
  }
  .menu-visible-drop {
    display: none;

    // display: flex;
    // flex-direction: column;
    justify-content: center;
    width: 237px;

    background-color: #ffffff;
    background: #ffffff;
    border-radius: 20px;

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 172.02%;

    color: #00585f;

    @media (min-width: 992px) {
      //   display: flex;
      // justify-content: center;
      display: none;
      width: 600px;
      height: 650px;

      border-radius: 20px;
      padding: 20px;
      margin-bottom: 50px;
    }
    > ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: 992px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 0.5rem;
        align-items: center;

        text-align: center;
      }
    }
    > li {
      display: flex;
      // padding: 0px 20px;
      line-height: 350%;

      @media (min-width: 992px) {
        display: flex;
        padding: 0px 20px;
        margin: 0px 10px;
      }
    }
  }

  .select-mobile {
    // position: absolute;
    width: 237px;
    height: 50px;

    background-color: #ffffff;
    background: #ffffff;
    border-radius: 100px;
    padding-left: 15px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 172.02%;

    color: #00585f;

    @media (min-width: 992px) {
      width: 600px;
      display: none;
    }

    .dropdown {
      padding: 20px;
    }
  }

  .topic {
    h2 {
      font-family: Bebas Kai;
      font-style: normal;
      font-weight: normal;
      font-size: 34px;
      line-height: 40px;
      /* or 118% */

      text-align: center;

      color: #0e2549;
      padding: 20px 0;
      @media (min-width: 992px) {
        font-size: 48px;
        // line-height: 118%;
      }
    }

    .first-container-cards {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: 992px) {
        flex-direction: row;
        align-content: center;
        justify-content: space-evenly;
        align-items: inherit;
        padding: 0px 150px;
        margin-top: 30px;
      }
      @media (min-width: 1440px) {
        
        padding: 0px 180px;
      }


      .card-blue {
        width: 300px;
        height: auto;
        background-color: #ffffff;
        border-radius: 20px;
        margin-bottom: 15px;
        @media (min-width: 992px) {
            width: 350px;
            

          }
        @media (min-width: 1440px) {
          width: 420px;
        }
        .bar-title {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 300px;
          height: 47px;
          background: #5590ba;
          border-radius: 20px 20px 0 0;

          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 172.02%;
          /* or 38px */

          text-align: center;

          color: #ffffff;
          @media (min-width: 992px) {
            width: 350px;
            

          }
          @media (min-width: 1440px) {
            width: 420px;
          }
          img {
            object-fit: contain;
            padding: 0 5px;
          }
        }
        .container-text {
          // width: 308px;
          padding: 31px 20px 31px 20px;

          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          /* or 150% */

          color: #000000;
          p {
            margin: 0;
            text-align: center;
          }
        }
      }

      .card-orange {
        width: 300px;
        height: auto;
        background-color: #ffffff;
        border-radius: 20px;
        margin-bottom: 15px;
        @media (min-width: 992px) {
            width: 350px;
            

          }
        @media (min-width: 1440px) {
          width: 420px;
        }
        .bar-title {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 300px;
          height: 47px;
          background: #de784c;
          border-radius: 20px 20px 0 0;

          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 172.02%;
          /* or 38px */

          text-align: center;

          color: #ffffff;
          @media (min-width: 992px) {
            width: 350px;
            

          }
          @media (min-width: 1440px) {
            width: 420px;
          }
          img {
            object-fit: contain;
            padding: 0 5px;
          }
        }
        .container-text {
          // width: 308px;
          padding: 31px 20px 31px 20px;

          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          /* or 150% */

          color: #000000;
          p {
            margin: 0;
            text-align: center;
          }
        }
      }
    }

    .second-container-cards {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: 992px) {
        flex-direction: row;
        align-content: center;
        justify-content: space-evenly;
        align-items: inherit;
        padding: 0px 150px;
      }
      @media (min-width: 1440px) {
        
        padding: 0px 180px;

      }

      .card-blue {
        width: 300px;
        height: auto;
        background-color: #ffffff;
        border-radius: 20px;
        margin-bottom: 15px;
        @media (min-width: 992px) {
            width: 350px;
            

          }
        @media (min-width: 1440px) {
          width: 420px;
        }
        .bar-title {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 300px;
          height: 47px;
          background: #5590ba;
          border-radius: 20px 20px 0 0;

          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 172.02%;
          /* or 38px */

          text-align: center;

          color: #ffffff;
          @media (min-width: 992px) {
            width: 350px;
            

          }
          @media (min-width: 1440px) {
            width: 420px;
          }
          img {
            object-fit: contain;
            padding: 0 5px;
          }
        }
        .container-text {
          // width: 308px;
          padding: 31px 20px 31px 20px;

          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          /* or 150% */

          color: #000000;
          p {
            margin: 0;
            text-align: center;
          }
        }
      }

      .card-orange {
        width: 300px;
        height: auto;
        background-color: #ffffff;
        border-radius: 20px;
        margin-bottom: 15px;
        @media (min-width: 992px) {
            width: 350px;
          }
        @media (min-width: 1440px) {
          width: 420px;
        }
        .bar-title {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 300px;
          height: 47px;
          background: #de784c;
          border-radius: 20px 20px 0 0;

          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 172.02%;
          /* or 38px */

          text-align: center;

          color: #ffffff;
          @media (min-width: 992px) {
            width: 350px;
          }
          @media (min-width: 1440px) {
            width: 420px;
          }
          img {
            object-fit: contain;
            padding: 0 5px;
          }
        }
        .container-text {
          // width: 308px;
          padding: 31px 20px 31px 20px;

          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          /* or 150% */

          color: #000000;
          p {
            margin: 0;
            text-align: center;
          }
        }
      }
    }
  }
  .contain-arrow {
    display: flex;
    justify-content: center;
    > button,
    > a {
      bottom: 1vw;
      position: fixed;
      right: 1vw;
      width: 11%;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
      cursor: pointer;
      @media (min-width: 992px) {
        width: 5%;
        bottom: 2vw;
        right: 2vw;


      }
    }
  }

  //DropdownMenu-Mobile

  .menudrop-mobile {
    @media (min-width: 992px) {
      display: none;
    }
  }
  // .dropbtn {
  //     background-color: transparent;
  //     color: #00585f;
  //     // padding: 16px;
  //     // font-size: 16px;
  //     border: none;
  //     cursor: pointer;
  //   }

  .dropdown {
    position: relative;
    display: inline-block;
    width: 237px;
    height: 50px;

    background-color: #ffffff;
    background: #ffffff;
    border-radius: 100px;
    cursor: pointer;
  }
  .contain-titlemenu {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: baseline;
    padding: 0 1.5rem;
    > p {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 172.02%;
      color: #00585f;
    }
    > img {
      object-fit: contain;
    }
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;

    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 172.02%;
    color: #00585f;
  }

  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }
}
