#reg-to-vote{
    padding:40px 20px 32px;
    background: #00585F;
    @media(min-width:992px){
        padding:80px 20px 82px;
    }
    .container{
        max-width: 377px;
        @media(min-width:992px){
            max-width:900px;
        }
    }

    h2{
        font-family: 'Bebas Kai', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 118%;
        /* identical to box height, or 57px */

        text-align: center;

        color: #E1F0EE;

        text-shadow: 0px 0px 10px rgba(117, 117, 117, 0.25);
        margin-bottom:29px;
    }

    p{
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        /* identical to box height */

        text-align: center;

        color: #E1F0EE;
        margin:41px 0 18px;
        @media(max-width:991px){
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            margin:16px 0 18px;
        }
    }
    a{
        font-family: 'Bebas Kai', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        text-decoration-line: underline;

        color: #DE784C;
    }
    .box-grid{
        display:grid;
        grid-gap: 19px;
        grid-template-columns: 1fr;
        @media(min-width:992px){
            grid-gap: 31px;
            grid-template-columns: 1fr 1fr;
        }
    }
    .content-box{
        border-radius: 20px;
        background: #5590BA;
        color:#fff;
        padding:28px 26px 34px;
        @media(min-width:992px){
            padding:39px 86px 41px 31px;
        }

        >h3{
            font-family: 'Manrope', sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 36px;
            line-height: 42px;
            text-align:left;
            
        }

        >p{
            font-family: 'Manrope', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 146.5%;
            color:#fff;
            min-height:85px;
            /* or 23px */
            text-align:left;
            margin: 28px 0 8px;
            @media(min-width:992px){margin: 19px 0;}
        }

        >.btn{
            background: #00585F;
             color:#fff;
            border-radius: 10px;
            text-decoration: none;
            padding:17px 14px 15px;
            font-family: 'Manrope', sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 85%;
            min-width:145px;
            display: inline-block;
            /* identical to box height, or 14px */

            text-align: center;
            @media(min-width:992px){
                padding:21px 31px;
            }
        }
        &:first-child{
            background: #E1F0EE;
            color: #00585F;
            p{
                color: #00585F;
            }
            >.btn{
                background: #5590BA;
            }
        }
    }

}