#footer{
    background: #202B40;
    padding: 34px 20px 31px;
    @media(min-width:992px){
        padding: 54px 20px 71px;
    }
    .container{
        max-width:358px;
        @media(min-width:992px){
            max-width:1066px;
        }
    }

    .logo{
        width:152px;
        display: block;
        margin:0 auto 20px;
        @media(min-width:992px){
           margin:0 0 20px;
        }
    }
    p{
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 85%;
        /* or 10px */
        text-align: center;
        color: #FFFFFF;
        width:100%;
    }

    h3{
        font-family: 'Bebas Kai', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 38px;

        color: #DE784C;
    }

    .list-icons{
       
        @media(max-width:991px){
            padding:0 25px 28px;
        }
        li{
            @media(max-width:991px){
                min-width:218px;
                margin: 22px auto 0;
                &:first-child{
                    margin: 25px auto 0;
                }
            }
            @media(min-width:992px){
                 margin-top: 25px;
            }
        }
        img{ margin:0 5px; padding:0;}
        h3{
            font-family: 'Bebas Kai', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 85%;
            /* or 15px */
            color: #50C5B4;
            margin: 0 5px -1px;
            padding: 0;
        }
        p{
            font-family: 'Manrope', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 85%;
            /* or 10px */

            text-align: center;

            color: #50C5B4;
            margin: 0 5px 0px;
        }
    }

    .submit-form{
        margin-top:30px;
        display: inline-block;
        max-width:411px;
        width:100%;
        .msg{
            background:#888;
            color:#fff;
            border-radius:10px;
            max-height:0px;
            padding:0;
            transition: max-height .1s, padding .1s;
            -webkit-transition: max-height .1s, padding .1s;

            &.error{
                background:#f57272;
            }
            &.success{
                background:#5ad07f;
            }
            &.active{
                padding:1px;
                max-height:500px;
                transition: max-height 1s;
                -webkit-transition: max-height 1s, padding .1s;
            }
            p{
                margin: 16px 25px;
                font-size: 14px;
                line-height: 16px;
                text-align: left;
            }
        }
    }
    form{
        font-family: 'Manrope', sans-serif;
        
        
        display: grid;
        grid-template-columns:1fr;
        background: #FFFFFF;

        @media(min-width:375px){
            box-sizing: border-box;
            border-radius: 20px;
            grid-template-columns:1fr 61px;
            overflow:hidden;
        }
        @media(min-width:992px){
             grid-template-columns:1fr 61px;
        }
       input{
           &[type="email"],&[type="text"]{
               font-family: 'Manrope', sans-serif;
                outline: 0;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;

                color: #00585F;

                background: #FFFFFF;
                box-sizing: border-box;
                border-radius: 10px 0px 0px 10px;

                color: #000000;
                padding: 23px 27px 27px 27px;
                @media(max-width:374px){
                    color: #000000;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 16px;
                    box-sizing: border-box;
                    border-radius: 10px;
                }
                @media(min-width:374px)and(max-width:992px){
                    padding: 25px 1em 25px;
                }

           }
           &[type="submit"],&[type="button"]{
               font-family: 'Manrope', sans-serif;
                outline: 0; 
                background: #5590BA;
                box-sizing: border-box;
                text-transform: uppercase;
                @media(max-width:374px){
                    border-radius: 10px;
                }

                cursor:pointer;
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                line-height: 24px;
                /* identical to box height */

                text-align: center;

                color: #FFFFFF;
                padding: 23px 17px 23px;
                @media(min-width:375px) and (max-width:991px){
                    padding: 23px 17px 23px;
                }
                @media(min-width:992px){

                    opacity:1;
                    transition: opacity .3s;
                    &:hover{
                        opacity:.65;
                        transition: opacity .5s;
                    }
                }
           }
       }
    }
    .social-media{
        padding:8px 0 30px;
        @media(min-width:992px){
            padding:34px 0;
        }
    }
}

.social-media{
    li{
        display: inline-block;
        margin-left:29px;
        &:first-of-type{
            margin-left:0;
        }
        a{
            height:25px;
            height:25px;
            object-fit: contain;
            object-position: center;
            display:inline-block;
        }
    }
}